<template>
  <div
    v-if="product"
    :class="[
      {
        'product-card-container': !renderInCartModal,
        'product-card-container-cart-modal': renderInCartModal,
        'search-page': renderSearch,
        'plp-page': renderPlp,
        'search-dropdown-height': dropdownSearchHeight,
      },
    ]"
  >
    <NuxtLink
      class="product-card"
      :to="localePath(productUrl)"
      :custom="!pdpLinkEnabled"
      @click="linkClickEmitter($event)"
    >
      <div class="gs-product-tag" v-text="tag" />
      <h3 class="gs-product-title mgn-btm-1" v-text="formattedTitle" />
      <div
        v-if="show_short_desc && selectedVariant?.short_description"
        class="gs-short-desc mgn-btm-1"
        v-html="selectedVariant.short_description"
      />
      <div class="image-wrapper" @mouseenter="setShowHoverImage(true)" @mouseleave="setShowHoverImage(false)">
        <picture class="flex">
          <source media="(max-width: 768px)" :srcset="generateCloudinarySrcset(imagesSwap[0], 270, 270)" />
          <source media="(max-width: 1280px)" :srcset="generateCloudinarySrcset(imagesSwap[0], 300, 300)" />
          <source :srcset="generateCloudinarySrcset(imagesSwap[0], 350, 350)" />
          <img
            :srcset="generateCloudinarySrcset(imagesSwap[0], 300, 300)"
            :height="state.imgSize"
            :width="state.imgSize"
            :alt="title"
            loading="lazy"
            :class="['image', { 'a2c-pinned-img': hideImageAtc }]"
          />
        </picture>
        <span v-if="product.product_details.height" class="h6 fw500 gs-product-caption gs-product-caption-height">{{
          product.product_details.height
        }}</span>
        <span v-if="product.product_details.diameter" class="h6 fw500 gs-product-caption gs-product-caption-diameter">{{
          product.product_details.diameter
        }}</span>
      </div>

      <div class="product-details">
        <h3 class="product-title h4 ff-text fw-500" v-text="title" />

        <!-- Prevent card nav clicking -->
        <PriceSection :sku="selectedVariant?.bc_variant?.sku || ''" :is-pdp="false" @click.prevent />

        <ProductOptions
          v-show="shouldOptionsShow"
          :sku="baseProduct?.sku"
          :option-default-override-sku="defaultVariantSku"
          :product-variations="productVariations"
          :number-options-to-show="deviceStore.isDesktop ? 6 : 3"
          @product-options:update="updateOption"
          @modifiers-changed="setProductModifiers"
        />
      </div>
    </NuxtLink>

    <div v-if="maxInCart" class="add-to-cart-error">Max Quantity Reached</div>
    <div v-if="show_add_to_cart" class="flex-inline">
      <AddToCartButton
        style="margin-top: auto"
        :product="cartProduct"
        :variant="state.bcVariant"
        :disable-btn="maxInCart || bcVariantDisabled || selectedVariant?.variant_disabled"
        :is-variant="hasOptions"
        :quantity="quantity"
        :images="imagesSwap"
        :is-small="true"
        :secondary-button="isCartPage || !!isCartModal"
        @added-to-cart="addedEvent"
      />
      <CustomButton
        v-if="hasEtchingAvailable"
        :to="localePath(customUrl)"
        class="mgn-l-pt5"
        :is-small="true"
        :aria-attrs="{
          'aria-label': `Personalize ${title}`,
        }"
        style-name="secondary"
        style-color="black"
        @click-button="linkClickEmitter($event)"
        >Personalize</CustomButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductV2 } from '@solo-stove/types/contentstack/stove/product';
import { CloudinaryAsset } from '@solo-stove/types/contentstack/stove/cloudinary';
import { useCloudinary } from '~/composables/cloudinary';
import { GtmEventType, mapProductCardsItemsToGtmEvent } from '~/util/analytics';
import { hasAncestorWithClass } from '~/util/eventHandler';
import { maxCartProducts } from '~/util/bigcommerce';
import { useLocaleStore } from '~/store/locale';
import { useCartStore } from '~/store/cart';
import { useRootStore } from '~/store';
import { useDeviceStore } from '~/store/device';
import { getAssetPublicId } from '~/util/contentstack/csHelpers';

const localePath = useLocalePath();
const route = useRoute();
const localeStore = useLocaleStore();
const cartStore = useCartStore();
const rootStore = useRootStore();
const deviceStore = useDeviceStore();
const cloudinary = useCloudinary();

const emit = defineEmits(['added', 'linkClick', 'closer']);
type PCLinkClickEmit = 'linkClick' | 'closer';
type PCAddedEmit = 'added';

const props = defineProps({
  emitLinkClick: {
    type: String as () => PCLinkClickEmit,
    default: undefined,
  },
  emitAdded: {
    type: String as () => PCAddedEmit,
    required: false,
    default: undefined,
  },
  product: {
    type: Object as () => ProductV2,
    required: true,
  },
  isDynamicSection: {
    type: Boolean,
    default: false,
    required: false,
  },
  queryID: {
    type: String,
    default: undefined,
  },
  renderMode: {
    type: String,
    default: '',
  },
  index: {
    type: Number,
    default: undefined,
  },
  /* eslint-disable vue/prop-name-casing */
  show_add_to_cart: {
    type: Boolean,
    default: false,
  },
  show_short_desc: {
    type: Boolean,
    default: false,
  },
  show_whats_included: {
    type: Boolean,
    default: false,
  },
  link_to_pdp: {
    type: String as () => 'enabled' | 'disabled',
    default: 'enabled',
  },
  defaultVariantSku: {
    type: String,
    default: undefined,
  },
  secondary_button_text: {
    type: String,
    default: 'Learn More',
  },
  /* eslint-enable vue/prop-name-casing */
});

const state = reactive({
  imgSize: 350,
  bcVariant: props.product?.product_offerings?.product_variations?.[0]?.bc_variant,
  showHoverImage: false,
  productModifiers: [] as any[],
});

const bcVariantDisabled = computed<boolean>(() => {
  return selectedVariant.value?.bc_variant?.purchasing_disabled || false;
});
const dropdownSearchHeight = computed<boolean>(() => {
  return props.renderMode === 'dropdown-search' && props.show_add_to_cart;
});
const renderSearch = computed<boolean>(() => {
  return props.renderMode === 'search';
});
const renderPlp = computed<boolean>(() => {
  return props.renderMode === 'plp';
});
const renderInCartModal = computed<boolean>(() => {
  return props.renderMode === 'cartModal';
});

const hideImageAtc = computed<boolean>(() => {
  return !['dropdown-search'].includes(props.renderMode);
});
const hasEtchingAvailable = computed<boolean>(() => {
  return props?.product?.etching_section?.enable_etching || false;
});
const pdpLinkEnabled = computed<boolean>(() => {
  return props.link_to_pdp === 'enabled';
});
const productDetails = computed(() => {
  return props.product?.product_details;
});

const productVariations = computed(() => {
  return props.product?.product_offerings?.product_variations;
});

const options = computed(() => {
  const variations = productVariations?.value;
  return variations?.length ? variations?.map((option: any) => option?.bc_variant) : [];
});

const cartProduct = computed<any>(() => {
  return {
    ...baseProduct?.value,
    ...(state.productModifiers?.length > 0 && { variantId: selectedVariant?.value?.bc_variant?.id }),
  };
});
const hasOptions = computed<boolean>(() => {
  return !!options?.value?.length;
});
const optionIndex = computed<number>(() => {
  let locatedIndex = 0;
  options.value.find((option: any, i: number) => {
    if (option?.id === state.bcVariant?.id) {
      locatedIndex = i;
      return true;
    }
    return false;
  });
  return locatedIndex;
});
const selectedVariant = computed(() => {
  const productVariations = props?.product?.product_offerings?.product_variations;
  return productVariations && productVariations[optionIndex.value] ? productVariations[optionIndex.value] : null;
});
const imagesSwap = computed<CloudinaryAsset[]>(() => {
  const newImages: Array<any> = selectedVariant.value
    ? (selectedVariant.value?.thumb?.length && selectedVariant.value?.thumb) || []
    : [];
  return newImages.concat(props.product?.product_offerings?.base_image_set);
});
const hoverImage = computed<CloudinaryAsset[] | undefined>(() => {
  return selectedVariant?.value?.thumb_hover;
});
const tag = computed<string | undefined>(() => {
  return props.product?.product_taxonomy?.tag;
});
const title = computed(() => {
  return selectedVariant?.value?.display_title || baseProduct?.value?.name;
  // return baseProduct?.value?.name;
});
const formattedTitle = computed(() => {
  return title.value.replace(/[0-9.]/g, '');
});
const baseProduct = computed(() => {
  return props.product?.product_offerings?.bc_primary_product?.product;
});
const productUrl = computed<any>(() => {
  return {
    path: props.product?.url,
    query: {
      ...(state.bcVariant?.sku && { sku: state.bcVariant.sku }),
      ...(props.queryID && { queryID: props.queryID }),
    },
  };
});
const customUrl = computed<any>(() => {
  return {
    path: props.product?.url,
    query: {
      ...(state.bcVariant?.sku && { sku: state.bcVariant.sku }),
      ...(hasEtchingAvailable?.value && { customizeOpen: true }),
      ...(props.queryID && { queryID: props.queryID }),
    },
  };
});
const quantity = computed<number>(() => {
  return productDetails?.value?.min_quantity || 1;
});
const shouldOptionsShow = computed<boolean>(() => {
  const approvedOptionPlacements = ['clp', 'home', 'plp', 'search', 'pdp', 'splash-page', 'blog'];
  const dropdownProductOptionsEnabled = rootStore.algoliaSettings?.dropdown_product_options || false;
  if (dropdownProductOptionsEnabled) approvedOptionPlacements.push('dropdown-search');
  const renderModeChecker = approvedOptionPlacements.includes(props.renderMode);
  return hasOptions?.value && renderModeChecker;
});
const isCartPage = computed<boolean>(() => {
  const path = route.path?.toLowerCase();
  return path === '/' + localeStore.langCode + '/cart';
});
const isCartModal = inject<boolean>('isCartModal', false);
const maxInCart = computed<boolean>(() => {
  return maxCartProducts(cartStore.products, baseProduct?.value, quantity.value, productDetails?.value?.max_quantity);
});

function addedEvent() {
  if (props.emitAdded) {
    emit(props.emitAdded);
    return false;
  }
}
function linkClickEmitter(event: MouseEvent) {
  const target = event.target as HTMLElement;
  const hasAncestor = hasAncestorWithClass(target.parentElement, 'options-container');
  // only track if not selecting variants/a2c
  if (!hasAncestor || target.classList.contains('product-card')) {
    const product = {
      product: props.product?.product_offerings?.bc_primary_product?.product,
      variant: selectedVariant.value?.bc_variant,
      hasVariants: hasOptions.value,
      index: props.index,
      quantity: quantity.value,
    };
    const gtm = useGtm();
    gtm?.trackEvent(mapProductCardsItemsToGtmEvent(GtmEventType.selectItem, [product as any]));
  }

  if (props.emitLinkClick) emit(props.emitLinkClick);
}

function setShowHoverImage(showHoverImage: boolean) {
  state.showHoverImage = showHoverImage;
}
function updateOption(updatedVariant: any) {
  state.bcVariant = updatedVariant;
}
function setProductModifiers(modData: any) {
  state.productModifiers = modData as any;
}
function generateCloudinarySrcset(image: CloudinaryAsset, width: number, height: number): string {
  const publicId =
    state.showHoverImage && hoverImage.value?.length ? getAssetPublicId(hoverImage.value as any) : image?.public_id;
  const srcset1 = `${cloudinary.bynderToCloudinaryResize(publicId, width, height, 1)} 1x`;
  const srcset2 = `${cloudinary.bynderToCloudinaryResize(publicId, width, height, 1.5)} 2x`;
  const srcset3 = `${cloudinary.bynderToCloudinaryResize(publicId, width, height, 1.75)} 3x`;
  return `${srcset1}, ${srcset2}, ${srcset3}`;
}
</script>

<style lang="scss">
.product-card {
  .additional-info,
  .additional-info p,
  .additional-info a {
    font-size: 0.75rem;
    line-height: 150%;
    max-width: fit-content;
  }
  .gs-short-desc {
    p {
      color: $color-neutral-cool-500;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>

<style lang="scss" scoped>
.product-card-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: -webkit-fill-available;
  &.search-page,
  &.plp-page,
  &.search-dropdown-height {
    height: 100%;
  }
  .add-to-cart-error {
    color: $color-danger-default;
    width: 100%;
    padding: 0.625rem 0;
  }

  .product-card {
    color: $color-neutral-cool-900;
    position: relative;
    .product-title {
      color: $color-neutral-cool-900;
      @include local-mixins.tablet_and_mobile {
        font-size: 1rem;
      }
    }

    .gs-product-title {
      color: $color-neutral-cool-900;
      font-size: 2rem;
      font-weight: 500;
    }
    .product-details {
      margin-top: 8px;
      @include local-mixins.desktop {
        margin-top: 12px;
      }
    }
    .gs-product-tag {
      width: fit-content;
      font-weight: 600;
      font-size: 0.7rem;
      line-height: 150%;
      border-radius: 2px;
      color: $color-primary-300;
    }
    .gs-product-caption {
      color: #ababab;
      font-size: 0.75rem;
      line-height: 16px;
      font-weight: 600;
      &-height {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) rotate(-90deg);
      }
      &-diameter {
        position: absolute;
        left: 50%;
        bottom: 3%;
        transform: translateX(-50%);
      }
    }

    :deep(.price-container) {
      .current-price,
      .orig-price,
      .orig-price span {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
    :deep(.options-container) {
      .selected-title {
        display: none;
      }
      .color-swatch {
        height: 20px;
        width: 20px;
      }
      .variant-wrapper {
        width: 100%;

        .option {
          padding: 8px;
          font-size: 0.75rem;
        }
        &.opt-button:not(.selected) {
          border: $color-neutral-cool-200 1px solid;
          border-radius: 3px;
          &:hover,
          &:focus {
            border: $color-neutral-black 1px solid;
          }
        }
        &.selected {
          border: $color-primary-500 2px solid;
          border-radius: 3px;
        }
        .select-expanded {
          @include local-mixins.tablet_and_mobile {
            min-width: revert;
            width: 100%;
          }
        }
      }
    }
    .image-wrapper {
      overflow: hidden;
      border-radius: 4px;
      position: relative;
    }
    picture:hover .image {
      transform: scale(1.1);
    }
    .image {
      background-color: $color-neutral-cool-50;
      transition: transform 0.2s;
      height: auto;
      width: 100%;
      border-radius: 4px;
      position: relative;
      // TODO - check commenting out this is okay
      // &.a2c-pinned-img {
      //   z-index: -1;
      // }
    }
  }
}
</style>
